
<template>
  <base-section id="marketing">
    <v-container>
      <v-row>
        <v-col
          cols="3"
          class="text-center hidden-sm-and-down"
        >
          <template v-for="(card, i) in cards">
            <base-avatar-card
              :key="card.title"
              data-aos="zoom-in"
              align="center"
              v-bind="card"
            />
            <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <base-subheading
            size="display-1"
            title="OUR SERVICES"
            space="0"
          />

          <base-title
            class="primary--text"
            title="App Devlopment, Web Design, Graphic Design & Consultation."
            tag="div"
          />

          <base-body>
            We handle full stack Development for Web Applications, Mobile Apps, Dynamic & Static Sites, Deployment, Maintenance, All Graphic Design & Tech Consultation.
          </base-body>

          <base-btn
            to="/services"
            class="mb-12"
          >
            Learn More
          </base-btn>

          <v-row>
            <v-col
              v-for="(text, i) in marketing"
              :key="i"
              cols="12"
              md="6"
            >
              <base-list-item :text="text" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'Ourservices',

    data: () => ({
      cards: [
        {
          title: 'App Development',
          icon: 'mdi-cellphone',
        },
        {
          title: 'Web Design',
          icon: 'mdi-desktop-mac-dashboard',
        },
        {
          title: 'Graphic Design',
          icon: 'mdi-lead-pencil',
        },
      ],
      marketing: [
        'Web Applications',
        'Mobile Applications',
        'Web Design',
        'Graphic Design',
        'SEO',
        'Consultaion',
        'IT Services',
      ],
    }),
  }
</script>
